.breadcrumbs {
	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
	color: #4DE091;
	display: flex;
  flex-wrap: wrap;
	padding: 18px 0;
}
.breadcrumbs .item {
	display: flex;
	align-items: center;
}
.breadcrumbs .item:first-child::before {
	content: '';
	padding-left: 10px;
	background: url(../images/home.svg) no-repeat;
	background-size: contain;
	width: 16px;
	height: 16px;
	display: flex;
}
.breadcrumbs .item:not(:last-child)::after {
	content: '';
	background: url(../images/arrow.svg) no-repeat;
	background-size: contain;
	display: block;
	height: 10px;
	width: 10px;
}
.breadcrumbs .non-a {
	text-decoration: none;
	color: #828282;
	margin-right: 10px;
}
.breadcrumbs a {
	text-decoration: none;
	color: #828282;
	margin-right: 10px;
}
.breadcrumbs a.hover:hover::after {
	background-color: #828282;
}

@media (min-width: 1200px) {
	.breadcrumbs {
		font-size: 16px;
		padding: 20px 0 20px;
	}
	.breadcrumbs .item:first-child::before {
		padding-left: 12px;
		width: 16px;
		height: 16px;
	}
	.breadcrumbs .item:not(:last-child)::after {
		height: 13px;
		width: 13px;
	}
}

@media (min-width: 1800px) {
	.breadcrumbs {
		padding: 25px 0 25px;
	}
	.breadcrumbs .item:first-child::before {
		width: 22px;
		height: 22px;
	}
}