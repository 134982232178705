.reviews{
    margin-bottom: 30px;

    .mySwipper{
        margin-bottom: 25px;
    }

    .review{
        text-align: left;
        background: white;
        padding: 0px 10px;

        .title{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }

        .rate{
            display: flex;
            margin-left: 20px;
        }
    }

    .swiper{
        width: 90%;
        height: 100%;
    }

    .swiper-slide{
        font-size: 16px;
        background-color: aliceblue;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 100px;
        width: auto;
        background: white;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev{
        right: 0px !important; 
        left: auto;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next{
        left: 0px !important; 
        right: auto;
    }
    .swiper-wrapper{
        align-items: center;
    }

    @media(max-width: 768px){
        .swiper-button-next:after, .swiper-button-prev:after{
            font-size: 20px;
        }

        .reviews{
            padding: 0px 12px;
            margin-bottom: 20px;
        }
    }
}