.horizontal-filter-block {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  box-shadow: 0 4px 16px 0 rgba(77,224,145,.3);
  border: 1px solid rgba(77, 224, 145, .25);
  border-radius: 4px;
  padding: 15px;
  .field {
    label {
      display: block;
      margin-bottom: 4px;
      font-weight: 700;
      font-size: 12px;
      color: #333;
    }
    &:not(.c2) {
      grid-column: 1 / span 2;
    }
  }
  .range {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;  
  }
  @media(min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .field {
      label {
        font-size: 14px;
      }
      &:not(.c2) {
        grid-column: initial;
      }
    }
  }
}

.select-wrapper {
  height: 20px;
  overflow-y: visible;
  margin-bottom: 15px;
  .select {
    width: 100%;
    /* make it min-height*/
    min-height: 20px;
    border-radius: 6px;
    border-color: #555;
    padding: 10px;
  }
}

