.checklist {
	margin: 0;
	padding: 0;
  list-style: none;
  color: #222;
	li {
		display: flex;
		font-weight: 500;
		font-size: 14px;
		&:not(:last-child) {
			margin-bottom: 15px;
		}
		&:before {
			content: '';
			@include checkmark($color: '%234DE091');
      display: flex;
      padding-right: 14px;
			height: 22px;
			width: auto;
			min-width: 22px;
			background-size: contain;
			background-repeat: no-repeat;
		}
		span {
			display: inline-block;
		}
		&.disabled1 {
			&:before {
				@include checkmark($color: '%23828282');
			}
			.gray {
				color: #828282;
			}
		}
		&.disabled2 {
			&:before {
				@include checkmark($color: '%23BDBDBD');
			}
			.gray {
				color: #bdbdbd;
			}
		}

		@media(min-width: 992px) {
			font-weight: 500;
			font-size: 16px;	
			&:not(:last-child) {
				margin-bottom: 17px;
			}	
		}
	}

	&.pluses {
		li {
			&:not(:last-child) {
				margin-bottom: 11px;
			}
			&:before {
				@include checkmark-plus($color: '%23C584E3');
			}
		}	
	}
}

.green-list {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 14px;
	color: #222;
	li {
		display: flex;
		&:not(:last-child) {
			margin-bottom: 8px;
		}
		&:before {
			content: '•';
      padding-right: 8px;
			color: #4DE091;
		}
	}
	a {
		text-decoration: none;
	}
}