.item-car-photos {
  .swiper-pagination {
    display: none;
  }
  [class^=swiper-button] {
    color: #fff;
  }
  @media(min-width: 1280px) {
    [class^=swiper-button] {
      display: none;
    }
    .swiper-pagination {
      z-index: 1;
      display: flex;
      height: 100%;
      width: 95%;
      top: 0;
      bottom: 0;
      justify-content: center;
      align-items: flex-end;
      margin: 0 10px;
      &-bullet {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 0;
        background: none;
        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 100%;
        }
        &::before {
          bottom: 5px;
          height: 4px;
          background: #4DE091;
        }
        &::after {
          left: 0;
          height: 100%;
        }
      }
    }
  }
}

.photos {
  position: relative;
  display: block;
  .nav {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 4px;
    padding: 0;
    margin: 0;
    list-style: none;
    .item {
      width: 25%;
      border-bottom: solid 4px #4DE091;
      opacity: 0.2;
      height: 100%;
      margin: 0 5px;
      list-style: none;
      &.active {
        opacity: 1;
      }
    }
  }
}