.credit-tradein {
	@media(min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 50px;
	}
	@media(min-width: 992px) {
    grid-template-columns: auto 60%;
    .right .card-item {
    	display: flex;
    }
	}
	@media(min-width: 1800px) {
		grid-template-columns: auto 60%;
		gap: 70px;
	}
}
