.advantages {
	color: #4f4f4f;
	font-size: 14px;
	@media(min-width: 992px) {
		padding: 30px 0; 
	}
	.item-title {
		color:  #333;
		text-align: center;
		font-weight: 700;
		font-size: 16px;
	}
	img {
		margin: 0 auto;
	}
	.items {
		display: grid;
		gap: 30px;
		@media(min-width: 768px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@media(min-width: 992px) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	&.advantages-type2 {
		.items {
			text-align: center;
		}
		@media(min-width: 992px) {
			img {
				padding-bottom: 20px;
			}
		}
	}
}
