.banks {
	.banks-list {
		.item {
      display: flex;
      align-items: center;
      padding: 3px;
      height: 48px; 
			background: #F3F3F3;
		}
	}
}

.banks-list {
  position: relative;
 	padding: 0 10px;
  [class^=swiper-button] {
  	color: #222;
    width: calc(24px/ 44 * 27);
    height: 24px;
    margin-top: calc(0px - (24px/ 2));
    &:after {
    	font-size: 22px;
    }
  }
  .swiper-button-prev {
  	left: -10px;
  }
  .swiper-button-next {
  	right: -10px;
  }
  img {
  	min-height: 20px;
  	max-height: 40px;
    max-width: 132px;
		width: 100%;
    margin: 0 auto;  	
  }
  @media(min-width: 1280px) {
  	padding: 0;
 		width: 100%;
	  .swiper-button-prev {
	  	left: -30px;
	  }
	  .swiper-button-next {
	  	right: -30px;
	  }
  }
}
