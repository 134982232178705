.contacts-items {
	.item {
		display: flex;
		align-items: center;
		gap: 15px;
		&:not(:last-child) {
			margin-bottom: 15px;
		}
		.item-data {
			font-weight: 700;
			font-size: 16px;	
		}
		.item-label {
			color: $gray3;
		}
		.item-value {
			&.lg {
				font-size: 20px;
			}
		}
	}
	@media(min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 10px;
		.item {
			&:not(:last-child) {
				margin-bottom: 0;
			}
			.item-data {
				font-size: 14px;	
			}
			.item-value {
				&.lg {
					font-size: 16px;
				}
			}
		}
	}
	@media(min-width: 992px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	@media(min-width: 1200px) {
		.item {
			.item-data {
				font-size: 16px;	
			}
			.item-value {
				&.lg {
					font-size: 20px;
				}
			}
		}
	}
}

.about-block {
	.text {
		background: #fff;
		border-radius: 6px;
		padding: 25px 25px 0;
		z-index: 1;
		position: relative;
		top: -50px;
		width: 90%;
		margin: 0 auto;
		.why-items {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				display: flex;
				align-items: center;
				margin: 0;
				padding: 0;
				gap: 24px;
				&:not(:last-child) {
					margin-bottom: 24px;
				}
			}
		}
	}
	@media(min-width: 768px) {
    position: relative;
    display: grid;
    grid-template-columns: 70% 60%;
    align-items: center;
		.text {
			padding: 15px 20px;
			left: -50%;
			top: 0;
			width: 100%;
		}
    .bg {
      border-radius: 6px;
    }
    .show-more-section-ellipsis {
      max-height: 200px;
    }    
	}
	@media(min-width: 992px) {
    .show-more-section-ellipsis {
      max-height: 250px;
    }       
  }
  @media(min-width: 1200px) {
		.text {
			left: -40%;
      padding: 20px 25px;
			background: rgba(255, 255, 255, 0.8);
			max-width: 400px;
			.why-items {
				margin-left: 30px;
			}
		}
    &.type2 {
      grid-template-columns: 50% 55%;
      .text {
        left: -10%;
        max-width: initial;
      }
    }
    .show-more-section-ellipsis {
      max-height: initial;
    }    
	}
}
