.contact-form-bl {
	background: url("../images/home/contact/contact_mobile_bg.webp");
	background-size: 100%;
	min-height: 300px;
	position: relative;
	overflow: hidden;
	.car {
		max-height: 200px;
		width: auto;
		position: relative;
		left: 40%;
		@media(max-width: 576px) {
			left: 0;
		}	
	}
	.form {
		color: #fff;
		background: #27AE60;
		width: 100%;
		height: 100%;
		padding: 0 20px 20px;
		text-align: center;
		.inner-form {
			z-index: 2;
			position: relative;
		}
		&::after,
		&::before {
		    content:"";
		    position: absolute;
		    width: 120%;
		    height: 230px;
		    background-color: #27AE60;
		    top: 165px;
		    z-index: 1;
		    left:-10%;
		    transform: rotate(5deg);
		    -moz-transform: rotate(5deg);
		    -webkit-transform: rotate(5deg);
		}
		&::before {
		    right:-10%;
		    transform: rotate(-5deg);
		    -moz-transform: rotate(-5deg);
		    -webkit-transform: rotate(-5deg);
		}
		@media(min-width: 1400px) {
			right: 70px;
		}
		.page-title {
			color: #fff;
		}
		.field {
			margin: 0 auto 16px;
			position: relative;
			max-width: 300px;
			&::before {
			    background-repeat: no-repeat;
			    background-size: 16px;
			    content: "";
			    width: 24px;
			    height: 24px;
			    position: absolute;
			    display: inline-block;
			    top: 10px;
			    bottom: 0;
			    right: 6px;
			}
			&.name::before {
				background-image: url("../images/home/contact/contact_name.svg");
			}
			&.phone::before {
				background-image: url("../images/home/contact/contact_phone.svg");
			}
		}
		.button {
			margin: 0 auto;
			background: #E3F1FF;
			color: #000;
			&:hover {
				opacity: 0.9;
			}
		}
	}
	@media(min-width: 576px) {
		background: url("../images/home/contact/contact_tablet_bg.webp");
		background-position-x: -50px;
	}
	@media(min-width: 768px) {
		background: url("../images/home/contact/contact_bg.webp");
		background-size: 200%;
		.car {
			left: 60%;
		}
	}
	@media(min-width: 992px) {
		background-position: -100px;
		background-size: initial;
		.car {
			position: absolute;
			left: 100px;
			max-height: 300px;
		}
		.form {
			padding: 20px;
			color: #fff;
			background: #27AE60;
			position: absolute;
			bottom: 0;
			right: 0;
			max-width: 440px;
			&::after,
			&::before {
				display: none;
			}
		}
	}
	@media(min-width: 1200px) {
		background-position: initial;
		.car {
			left: 180px;
		}
	}
	@media(min-width: 1400px) {
		.car {
			max-height: 350px;
		}
		.form {
			right: 70px;
		}
	}
}
