.head-block {
	padding-top: 30px;
	padding-bottom: 20px;
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  @media(min-width: 992px) {
    gap: 20px;
    flex-direction: row;
    align-items: center;
  }
}

.sort-block {
	display: flex;
	align-items: center;
	gap: 15px;
	.ss-main {
		padding: 5px 10px;
		background-color: #F3F3F3;
		border-color: #F3F3F3; 
		color: #828282;
		margin-right: 6px;
		.ss-single {
			font-weight: 400;
		}
	}
	.ss-arrow {
		width: 12px;
		height: 12px;
		path {
			stroke: #bdbdbd;
		}
	}

	.change-view {
    border: none;
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    background-color: transparent;
	  display: none;
	  .icon {
	  	width: 24px;
	  	height: 24px;
	  	fill: #bdbdbd;
	  	transition: fill .3s;
	  }
	  &:hover:not(.active) {
	  	opacity: 0.8;
	  }
	  &.active {
	  	.icon {
	  		fill: #4DE091;
	  	}
	  }
	}
	@media(min-width: 1280px) {
		.change-view {
			display: block;
		}
	}
}
.sort-by {
	.ss-list .ss-option {
		padding: 5px 10px;
		background-color: #F3F3F3;
		border-color: #F3F3F3; 
		color: #828282;
	}
	&.ss-content.ss-open-below {
		border: solid 1px #F3F3F3;
		margin-top: 0 !important; 
	}
}
.form-item-sort-bef-combine label,
form .form-item-sort-bef-combine {
	display: none;
}
