// Слайдер для фото авто //
.detail-car-photos:not(.swiper-initialized) .swiper-wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  overflow: hidden;
  .swiper-slide {
    width: auto;
    img {
      max-height: 550px;
      width: auto;
    }
  }
}
.detail-car-photos {
  width: 100%;
  @media(min-width: 992px) {
    padding-bottom: 40px;
  }
  .swiper-pagination-bullet {
    background-color: #4DE091;
    width: 12px;
    height: 12px;
  }
  .container {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    @media(min-width: 992px) {
      top: 45%;
    }
  }
  [class^=swiper-button] {
    color: #fff;
    @media(max-width: 992px) {
      margin-top: 0 !important;
    }
  }
  .swiper-button-prev {
    left: 2px;
  }
  .swiper-button-next {
    right: 2px;
  }
}

.characteristics {
  .cols {
    display: grid;
    @media(min-width: 768px) {
      grid-template-columns: repeat(auto-fit,minmax(200px,auto));
    }
  }
  .actions-box {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    .col2 {
      grid-column: 1 / span 2;
    }
    .info {
      display: flex;
      justify-content: space-between;
    }
    .guaranty {
      display: flex;
      .year {
        padding-left: 6px;
        font-size: 12px;
        color: #bdbdbd;
        span {
          width: 100%;
          display: block;
          font-weight: 700;
          font-size: 14px;
          color: #222;
        }
      }
    }
    .price-info {
      display: grid;
      @media(min-width: 992px) {
        grid-template-columns: 1fr 1fr;
        align-items: center;
      }
    }
    .price {
      font-weight: 700;
      font-size: 24px;
      color: #000;
      &-label {
        font-weight: 200;
        font-size: 16px;
      }
    }
    .button-call {
      max-width: 200px;
      background: #F3F3F3;
      border-radius: 6px;
      height: 32px;
      padding: 4px;
      display: flex;
      font-weight: 700;
      font-family: 'Roboto', sans-serif;
      text-decoration: none;
      color: #4f4f4f;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        padding-right: 4px;
        background-repeat: no-repeat;
        background-position: center;
      }
      &-phone {
        &:before {
          background-image: url(../images/sm-phone.svg);
        }
      }
      @media(min-width: 1200px) {
        font-size: 16px;
        padding: 4px 8px;
        &:before {
          padding-right: 8px;
        }
      }
    }
  }
  .ttc {
    order: 2;
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    margin: 0;
    padding: 0;
    list-style: none;
    color: #333;
    font-weight: 500;
    font-size: 16px;
    gap: 10px;
    @media(min-width: 768px) {
      order: initial;
    }
    li {
      display: flex;
      gap: 14px;
      &::before {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
      }
      &.mileage::before {
        background-image: url(../images/card/detail_mileage.svg);
      }
      &.year::before {
        background-image: url(../images/card/detail_year.svg);
      }
      &.owners-amount::before {
        background-image: url(../images/card/detail_owners_amount.svg);
      }
      &.gear::before {
        background-image: url(../images/card/detail_gear.svg);
      }
      &.drive::before {
        background-image: url(../images/card/detail_drive.svg);
      }
      &.color::before {
        background-image: url(../images/card/detail_color.svg);
      }
      &.volume::before {
        background-image: url(../images/card/detail_volume.svg);
      }
      &.power::before {
        background-image: url(../images/card/detail_power.svg);
      }
      &.engine::before {
        background-image: url(../images/card/detail_engine.svg);
      }
      .label {
        display: block;
        color: #bdbdbd;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
    @media(min-width: 992px) {
      order: initial;
      grid-template-columns: repeat(3,minmax(0,1fr));
      gap: 32px;
      font-size: 18px;
      li::before {
        width: 40px;
        height: 40px;
      }
    }
  }
  @media(min-width: 992px) {
    .actions-box {
      border: 3px solid #e0e0e0;
      border-radius: 6px;
      padding: 25px 35px;
      margin-bottom: 0;
      .price {
        &-label {
          font-size: 18px;
        }
      }
    }
  }
}

.options-group {
  .option {
    box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.04), 0px 15.4074px 19.1111px rgba(0, 0, 0, 0.0242963), 0px 3.25926px 4.88889px rgba(0, 0, 0, 0.0157037);
    border-radius: 6px;
    &:not(:first-child) {
      .option-header {
        margin-top: 40px;
      }
    }
    .option-header {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 15px 20px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
    }
    .option-icon-plus {
      transition: all 100ms ease-out;
    }
    &.is-active {
      .option-icon-plus {
        transform: rotate(45deg);
      }
    }
    .option-text {
      padding-bottom: 20px;
    }
  }
  .options-list {
    column-count: 2;
    grid-column-gap: 40px;
    li:not(:last-child) {
      margin-bottom: 10px;
    }
    @media(max-width: 376px) {
      column-count: 1;
    }
    @media(min-width: 1200px) {
      column-count: 3;
    }
  }
}

.contacts-info {
  font-size: 16px;
  .up {
    text-transform: uppercase;
  }
  .cols {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .b {
    font-weight: 700;
    font-size: 14px;
    &.ico {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      &::before {
        content: '';
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 6px;
      }
      &.address::before {
        background-image: url(../images/map.svg);
      }
      &.phone::before {
        background-image: url(../images/tel.svg);
      }
      &.time::before {
        background-image: url(../images/time.svg);
      }
    }
  }
  @media (min-width: 728px) {
    .b {
      font-size: 16px;
    }
  }
  @media (min-width: 992px) {
    .cols {
      display: grid;
      grid-template-columns: 0.5fr 1fr;
    }
  }
}


// Слайдер для "Похожие автомобили" //
.simular-cars-items .swiper:not(.swiper-initialized) .swiper-wrapper {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, 1fr);
}
.simular-cars-items {
  position: relative;
  [class^=swiper-button] {
    color: #BDBDBD;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
@media(min-width: 1280px) {
  .simular-cars-items {
    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
    }
    .swiper-button-prev {
      left: -40px;
    }
    .swiper-button-next {
      right: -40px;
    }
  }
}

.info-redirect{
  list-style: none;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  /*text-decoration: none;*/
  transition: .3s;

  &:hover{
    opacity: .7;
  }
}

.button-fav {
  background: white;
  padding: 0;
  &:before {
    content: '';
    display: block;
    background: center url("../images/heart.svg") no-repeat;
    background-size: 24px;
    width: 24px;
    height: 24px;
  }

  &:hover{
    background-color: white!important;
    opacity: .7;
  }
  span {
    display: none;
  }
}

.button-fav-filled {
  &:before {
    background: center url("../images/heart-fill.svg") no-repeat;
    background-size: 31px;
  }
}

.car-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: start;
  }

  .right{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media (max-width: 992px) {
      flex-direction: row-reverse;
      width: 100%;
      margin-top: 10px;
    }
  }
}
