.ad-services {
	.list {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 30px;
		.item {
			text-align: center;
			img {
				margin: 0 auto;
			}
		}
	}
	@media(min-width: 992px) {
		.list {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}
	}
}