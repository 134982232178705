.white-arrows {
	.swiper-button-next, 
	.swiper-button-prev {
		color: #fff;
	}
}

.header-slider {
	position: relative;
	.container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	& > figure {
		visibility: hidden;
		&:first-child {
			visibility: visible;
		}
	}
	.swiper-button-prev,
	.swiper-button-next {
		display: none;
		@media (min-width: 992px) {
			display: flex;
		}
	}
	.swiper-pagination {
		padding-left: 14px;
    text-align: left;
    position: initial;
    .swiper-pagination-bullet {
    	background: #4DE091;
			width: 50px;
			height: 5px;
	    border-radius: 0;
    }
    @media (min-width: 992px) {
    	display: none;
    }
	}
}



.lg-map {
  height: 250px;
  width: 100%;
  &.contacts {
  	padding-top: 0;
  }
  @media(min-width: 992px) {
  	height: 400px;
   	&.contacts {
  		padding-top: 50px;
  	}
  }
}