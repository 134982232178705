.hover {
  display: inline-block;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: inherit;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

// sticky footer //
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.header, .footer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}
main {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

a {
  transition: .4s ease;
}