.valuation-block {
	background: #F8F8F8;
	margin-top: 40px;
	.columns {
		padding: 30px 0 0 0;
	}
	.fieldset {
		margin-bottom: 8px;
		label {
			font-family: 'Inter', sans-serif;
			font-weight: 700;
			font-size: 12px;
			color: $black3;
			margin-bottom: 8px;
			display: block;
		}
		.cols {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: 15px;
		}
	}
	.button {
		margin-top: 22px;
	}
	@media(min-width:1800px) {
		margin-top: 64px;
		.columns {
	    display: flex;
	    justify-content: space-between;
	    padding: 0;
	    align-items: center;
	    gap: 70px;
			.column {
				width: 50%;
				&:last-child {
					display: block;
				}
			}
		}
	}
}