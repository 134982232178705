.footer {
  padding-top: 40px;
	background: #F3F3F3;
	width: 100%;
	font-weight: 400;
	font-size: 14px;
	color: #000;
  .logo {
    max-width: 400px;
  }
  .simple-toggle-block {
    .gray {
      color: #828282;
    }
    .m-toggle {
      display: inline-block;
      margin: 0 0 10px;
      color: #4de091;
      border-bottom: dashed 1px;
      cursor: pointer;
    }
    .hide {
      display: none;
    }
  }
  .cols2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 60px;  
  }
  .cols4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 60px;
    & > *:nth-child(3n),
    & > *:nth-child(4n) {
      grid-column: span 2;
    }
  }  
  .marks-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  a {
    color: #4f4f4f;
    transition: opacity 0.3s, visibility 0.3s;
    &:hover {
      opacity: .8;
    } 
  }
  .name-bl {
    margin-bottom: 30px;
  }
  .name {
    display: inline;
    margin: 0;
    padding: 0 40px 0 0;
    font-weight: 700;
    font-size: 16px;
    color: #222;
    text-transform: uppercase;
    border-bottom: solid 1px #fff;
  }
  .col {
    p::last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) {
    .simple-toggle-block {
      .m-toggle {
        display: none;
      }
      .hide {
        display: block;
      } 
    }
    .marks-list {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .cols4 {
      grid-template-columns: 1fr 1fr 1fr;
      & > *:nth-child(3n) {
        grid-column: initial;
      }
      & > *:nth-child(4n) {
        grid-column: span 3;
      } 
    }
  }
  @media (min-width: 992px) {
    .marks-list {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .cols4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      & > *:nth-child(4n) {
        grid-column: initial;
      } 
    }
  }
  @media (min-width: 1200px) {
    .marks-list {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
