.button {
	border-radius: 6px;
	border: none;
	text-decoration: none;
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
	transition: .3s;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	background-color: #4DE091;
	min-height: 35px;
	padding-left: 15px;
	padding-right: 15px;
	&.button-gray {
		background-color: #F3F3F3;
		&:not(:disabled):hover {
			background-color: #F3F3F3;
		}
	}
	&.button-purple {
		background-color: #C584E3;
		&:not(:disabled):hover {
			background-color: #A770C1;
		}
	}
	
	&:disabled {
		background-color: #bdbdbd;
		cursor: default;
	}
	&:not(:disabled):hover {
		background-color: #33FF92;
	}

	&.button-lg {
		height: 48px;
    font-size: 16px;
		@media(min-width: 992px) {
			height: 52px;
      font-size: 18px;
		}
	}

  img {
    &.left {
      margin-right: 8px;;
    } 
  }

	.b {
		font-weight: 700;
	}
	&.w100 {
		width: 100%;
	}
	&.center {
		margin: 0 auto;
	}

	&.button-bordered {
		color: #333;
		border: solid 2px #4DE091;
		background-color: #fff;
		&:not(:disabled):hover {
			border-color: #fff;
			color: #fff;
		}
		&.button-bordered-purple {
			border-color: #C584E3;
			&:not(:disabled):hover {
				border-color: #D987FF;
				background-color: #D987FF;
			}
		}
		&.button-rows2 {
			line-height: 1;
			display: block;
			font-size: 12px;
			font-weight: 400;
			.lg {
				display: block;
				font-size: 14px;
				font-weight: 500;
			}
		}
	}

	&.caps {
		text-transform: uppercase;
	}

	@media (min-width: 1200px) {
		font-size: 16px;
	}
}

.show-all {
	position: relative;
	bottom: -20px;
	display: none;
	.line {
		border-bottom: solid 1px #bdbdbd;
		width: 100%;	
	}
  &.active {
    .show-all-button {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
.show-all-button {
	z-index: 2;
	position: absolute;
	left: 40px;
	top: -16px;
	font-family: 'Inter', sans-serif;
	font-weight: 700;
	font-size: 12px;
	color: #bdbdbd;
	border-radius: 3px;
	border: solid 1px #bdbdbd;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	cursor: pointer;
	padding: 0 20px;
	text-decoration: none;
	img {
		margin-right: 16px;
	}
	&:after,
	&:before {
		content: "";
    width: 12px;
    height: 32px;
    left: -13px;
    position: absolute;
    background: #fff;
	}
	&:after {
		right: -13px;
		left: initial;
	}
}

.url-button {
	border: none;
	color: #222;
	text-decoration: underline;
	width: 100%;
	display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 0;
  cursor: pointer;
}
