.d-flex {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.d-flex.wrap {
  flex-wrap: wrap;
}
.d-flex.a-start {
  justify-content: flex-start;
}

.a-center {
  align-items: center;
}

/* Grid */
.d-grid {
  display: grid;
  gap: 20px;
}
.d-grid.cols2,
.d-grid.cols3,
.d-grid.cols4 {
  grid-template-columns: repeat(1,minmax(0,1fr));
}
@media(min-width: 768px) {
  .d-grid.cols2 {
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
  .d-grid.cols3 {
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
  .d-grid.cols4 {
    grid-template-columns: repeat(2,minmax(0,1fr));
  }  
}
@media(min-width: 992px) {
  .d-grid.cols3 {
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
  .d-grid.cols4 {
    grid-template-columns: repeat(4,minmax(0,1fr));
  }
}
@media(min-width: 1800px) {
  .d-flex,
  .d-grid {
    gap: 30px;
  }
}
.mt0 {
  margin-top: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
