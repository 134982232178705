.header {
  width: 100%;
  z-index: 101;
  background-color: #F3F3F3;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 16px;
  &.home-sticky,
  &.sticky {
    display: flex;
    box-shadow: 0 10px 35px rgba(0,0,0,.2);
  }
  &.sticky {
    position: fixed;
  }
  .container {
    height: 50px;
    display: grid;
    grid-template-columns: 1fr auto auto;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  a {
    text-decoration: none;
    color: #333;
  }
  .logo {
    height: 38px;
    img {
      height: 100%;
      width: auto;
    }
    .slogan {
      position: relative;
      left: 42px;
      top: -15px;
      letter-spacing: 2.5px;
      font-family: Inter,sans-serif;
      color: #4DE091;
      text-decoration: none;
    }
    @media(max-width: 360px) {
      img {
        max-height: 32px;
      }
      .slogan {
        left: 34px;
        top: -12px;
        letter-spacing: 1px;
      }
    }
  }
  .menu-block {
    .menu {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      text-transform: uppercase;

      @media (max-width: 996px){
        position: relative;
      }

      .phone {
        display: flex;
        align-items: center;
        &:before {
          content: "";
          background: url(../images/sm-phone.svg) no-repeat;
          background-size: 18px;
          width: 18px;
          height: 18px;
          padding-right: 5px;
        }
      }
      li {
        color: #4de091;
      }
      a{
        font-size: 13px;
      }
    }
  }
  @media(max-width: 992px) {
    .menu-block {
      position: fixed;
      top: -200%;
      left: 0;
      width: 100%;
      transition: 0.4s ease;
      padding: 8px 0 22px;
      background: #fff;
      &.menu-show {
        top: 0;
        overflow: scroll;
      }
      .mob-head {
        display: flex;
        position: relative;
        width: 100%;
        justify-content: space-between;
        padding: 0 32px 20px;
        .gray-logo {
          max-width: 220px;
        }
        .menu-close {
          position: relative;
          right: -15px;
        }
      }
      .menu {
        flex-direction: column;
        li {
          width: 100%;
          display: flex;
          align-items: center;
          &:not(:last-child) {
            border-bottom: solid 1px #F3F3F3;
          }
          &:not(:last-child) .item:after {
            content: "";
            width: 20px;
            height: 20px;
            background: url(../images/marrow.svg) no-repeat;
          }
        }
        .item {
          width: 100%;
          padding: 20px 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:last-child .a {
            width: 100%;
          }
          &.hover::after {
            position: initial;
            transform: initial;
          }
          &.call-contacts {
            flex-direction: column;
            text-align: center;
          }
        }

        .phone {
          width: 100%;
          background: #F3F3F3;
          justify-content: center;
          padding: 12px;
          border-radius: 6px;
          line-height: 1;
          margin: 10px 0;
        }
      }
    }
  }
  @media(min-width: 993px) {
    font-size: 14px;
    .container {
      display: flex;
    }
    .mob-head,
    .mob-item {
      display: none;
    }
    .menu-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menu {
        gap: 20px;
      }
    }
    .call-contacts {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 190px;
      text-align: right;
      .phone {
        font-size: 18px;
      }
      .worktime {
        text-transform: initial;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.4px;
      }
    }
  }
  @media(min-width: 1280px) {
    font-size: 16px;
    .menu-block {
      .menu {
        gap: 40px;
      }
    }
  }

  .brands-container{
    height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @media(min-width: 996px){
      position: relative;
    }

    &:hover{
     .brands{
      display: block;

      li{
        transition: .2s;
      }

      li:hover{
        opacity: .5;
      }
     }
    }

    .brands{
      display: none;
      background-color: #F3F3F3;
      position: absolute;
      column-count: 5;
      padding: 10px 20px;
      box-shadow: -3px 9px 12px rgba(0, 0, 0, 0.15);
      top: 100%;

      @media(max-width: 996px){
        display: none;

        &.opened{
          background-color: white;
          display: block;
          overflow-y: scroll;
          top: 0px;
          column-count: 1;
          width: 100%;
          z-index: 1;
          padding: 0;

          ul{
            padding: 0;
          }

          li{
            padding: 12px;
            border-bottom: 1px solid rgb(210, 210, 210);

            &.item{
              border-top: 1px solid rgb(210, 210, 210);
              padding: 15px 10px;

              a{
                margin-left: 40px;
              }
              &::after{
                position: absolute;
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      ul{
        padding: 0px;
        padding-left: 5px;

        li{
          padding: 5px 0px;
        }
      }
    }
  }
}
