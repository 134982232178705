.modal {
  .page-title {
    gap: 10px;
    margin-bottom: 15px;
  }
  .loader {
  	margin: 0 auto;
  }
  .checklist {
  	display: none;
  }
}