.progress {
  position: relative;
  margin: 4px;
  float: left;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  .percent {
    color: #c584e3;
    font-weight: 600;
    font-size: 20px;
    position: relative;
    top: -30px; /* bring the numbers up */
  }
  .text {
    font-weight: 500;
    font-size: 12px;
    color: #828282;
    margin-top: -20px;
  } 
}
.barOverflow { /* Wraps the rotating .bar */
  position: relative;
  overflow: hidden; /* Comment this line to understand the trick */
  width: 144px; 
  height: 72px; /* Half circle (overflow) */
}
.bar {
  position: absolute;
  top: 0; 
  left: 0;
  width: 144px; 
  height: 144px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 12px solid #f3f3f3;
  border-bottom-color: #c584e3;
  border-left-color: #c584e3;
  transform: rotate(-45deg);
}
