/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// Текстовые поля //
.price-field {
  position: relative;
  width: 100%;
  .textfield,
  .textfield-mini {
    width: 100%;
    padding-right: 25px;
  }
  &::after {
    content: '₽';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &.price-field-mini {
    max-width: 122px;
  }
}
.textfield {
  width: 100%;
  padding: 12px 20px;
	border: solid 1px #bdbdbd;
  border-radius: 3px;
	background-color: #fff;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	transition: .2s cubic-bezier(.37,0,.63,1);
  &.sm {
    padding: 8px 10px;
  }

	&:focus {
		border-color: #4de091;
	}
	&:hover {
		border-color: #33ff92;
	}
}


// Флажки //
.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
	font-size: 12px;
	color: #828282;
	a {
		text-decoration: underline;
		color: #828282;
	}
	input[type="checkbox"] {
    display: grid;
    place-content: center;
    float: left;
		appearance: none;
    margin: 0;
    width: 20px;
    height: 20px;
	  background-color: rgba(77, 224, 145, 0.5);
		font-size: 10px;
	  border-radius: 2px;
	  transform: translateY(-0.075em);
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      background-color: #fff;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    &:checked::before {
      transform: scale(1);
    }
	}
}


// Переключатели //
.switch {
  position: relative;
  display: inline-block;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: #4DE091;
    }
    &:checked + .slider:before {
      transform: translateX(20px);
    }
    &:checked + .slider + .label {
      color: #4DE091;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e0e0e0;
    transition: .4s;
    width: 44px;
    height: 24px;
    &:before {
      position: absolute;
      content: "";
      height: 22px;
      width: 22px;
      left: 1px;
      bottom: 1px;
      background-color: #fff;
      transition: .4s;
    }
    /* Rounded sliders */
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
  .label {
    transition: all .2s ease-in;
    margin-left: 50px;
/*    color: #e0e0e0;*/
    color: #828282;
    font-size: 16px;
    cursor: pointer;
  }
}


// Небольшие текстовые поля ввода //
.fieldset-range {
  padding-top: 20px;
  .value-block {
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: 188px 1fr;
    &.month-block {
      display: flex;
    }
    @media(max-width: 576px) {
      grid-template-columns: auto auto;
    }
  }
  .slider-range {
    label {
      font-weight: 500;
      font-size: 14px;
    }
    .month {
      position: relative;
      width: 100px;
      &::after{
        content: "мес.";
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .textfield-mini {
      &-sm {
        width: 40px;
      }
      height: 30px;
      max-width: 122px;
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      display: flex;
      align-items: center;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #333;
      border: solid 1px #e0e0e0;
      border-radius: 3px;
			&:focus {
				border-color: #4de091;
			}
			&:hover {
				border-color: #33ff92;
			}
    }
    &-block {
      padding: 40px 5px 0;
    }
  }
}

.unselectable {
	user-select:none;
	-o-user-select:none;
	-moz-user-select:none;
	-khtml-user-select:none;
	-webkit-user-select:none;
	cursor: pointer;
}



// Выпадающий список //

select {
/*  appearance: none;*/
  width: 100%;
  padding: 0 10px;
  border: solid 1px #bdbdbd;
  border-radius: 3px;
  background-color: #fff;
  font-family: Inter,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  transition: .2s cubic-bezier(.37,0,.63,1);
  height: 35px;
  box-sizing: border-box;
}
