.card-title {
	margin: 0 0 6px;
	padding: 0;
  font-weight: 700;
  font-size: 16px;
}
a.card-title {
	text-decoration: none;
	color: #000;
	&:hover {
		color: #4DE091;
	}
}
.card {
	&-valid {
    display: flex;
    align-items: center;
		font-weight: 500;
		font-size: 12px;
		color: #000;
		background-color: #F3F3F3;
		padding: 2px 8px;
		border-radius: 4px;
    border: none;
		cursor: pointer;
		margin-bottom: 10px;
		&:before {
			content: '';
			width: 18px;
			height: 18px;
			background: url("../images/card/valid.svg") no-repeat;
			background-size: 18px;
			display: flex;
			margin-right: 4px;
		}
	}

	&-item {
    border: 1px solid #F3F3F3;
    border-radius: 4px;
	position: relative;

		.new-auto{
			z-index: 1;
			background: #4de091;
			color: white;
			width: 75px;
			font-size: 11px;
			height: 70px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			position: absolute;
			top: -15px;
			right: -5px;
			height: 40px;
			border-radius: 20px;
			width: 110px;
			font-size: 13px;
		}

		.box {
			&.photos {
				display: flex;
				overflow-x: hidden;
				
				&.mobile{
					display: none;
					overflow-x: scroll;
					img {
						max-height: 150px;
						&:not(.first) {
							margin-left: 6px;
						}
					}
				}

				@media(max-width: 992px) {
					&.mobile{
						display: flex;
					}

					&.desctop{
						display: none;
					}
				}
			}
			&:not(.photos) {
				padding: 10px 10px 0;
			}
      &.price-box {
        padding-bottom: 10px;
      }
			@media(min-width: 1280px) {
				&:not(.photos) {
					padding: 5px;
				}
			}
			@media(min-width: 1800px) {
				&:not(.photos) {
					padding: 10px;
				}
			}
		}

		.cta-buttons {
			display: flex;
			gap: 10px;
			button{
				background: rgb(250, 50, 50);
			}
			.fav-icon{
				height: 20px;
				position: relative;
				z-index: -1;
				cursor: pointer;
			}
			.button {
				min-width: 40px;
				min-height: 40px;
				@media(min-width: 1280px) {
				min-width: 32px;
				min-height: 32px;
			}
      }
      .button-call {
        padding: 0;
        &:before {
          content: '';
          display: block;
          background: center url("../images/sm-phone.svg") no-repeat;
          background-size: 16px;
          width: 16px;
          height: 16px;
        }
        span {
          display: none;
        }
      }

	  .button-fav {
		background: white;
        padding: 0;
        &:before {
          content: '';
          display: block;
          background: center url("../images/heart.svg") no-repeat;
          background-size: 24px;
          width: 24px;
          height: 24px;
        }
		&:hover{
			opacity: .7;
		}
        span {
          display: none;
        }
      }

	  .button-fav-filled {
        &:before {
          background: center url("../images/heart-fill.svg") no-repeat;
		  background-size: 31px;
        }
      }
		}
	}

 	&-ttc {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 5px;
      row-gap: 5px;
			margin: 0;
			padding: 0;
			list-style: none;
      color: #333;
      font-weight: 400;
      font-size: 12px;
			li {
				display: flex;
				align-items: center;
				gap: 2px;
				&:before {
					content: '';
					display: block;
					background-repeat: no-repeat;
					background-size: 18px;
					width: 24px;
		    	height: 24px;
		    	margin-right: 4px;
				}
				&:nth-of-type(1)::before {
					background-image: url("../images/card/icons/calendar.svg");
				}
				&:nth-of-type(2)::before {
					background-image: url("../images/card/icons/engine.svg");
				}
				&:nth-of-type(3)::before {
					background-image: url("../images/card/icons/milleage.svg");
				}
				&:nth-of-type(4)::before {
					background-image: url("../images/card/icons/kpp.svg");
				}
				&:nth-of-type(5)::before {
					background-image: url("../images/card/icons/key.svg");
				}
				&:nth-of-type(6)::before {
					background-image: url("../images/card/icons/drive.svg");
				}
			.label {
				display: block;
        margin-bottom: 2px;
				color: #bdbdbd;
				font-weight: 400;
				font-size: 12px;
			}
		}
    @media(min-width: 1280px) {
        font-size: 12px;
        li {
          align-items: initial;
/*          &::before {
            background-size: 16px;
            width: 22px;
            height: 22px;
          }*/
        }
    }
    @media(min-width: 1800px) {
      font-size: 14px;
    }
 	}

	&-price {
		font-weight: 700;
		font-size: 14px;
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		gap: 12px;
		.credit-label {
			color: rgb(250, 50, 50);
		}
		@media(min-width: 1400px) {
			font-size: 16px;
		}
	}
}

.simular-cars-items {
	.card-item .box.photos img {
		max-height: initial;
	}
}

// Вид - список //
.view-list {
  .card {
    @media(min-width: 1280px) {
      &-item {
        .box:not(.photos) {
          padding: 5px 20px;
        }
        .card-price {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: flex-start;
          padding-bottom: 5px;
        }
        .price-box {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: center;
        }
        .cta-buttons {
          display: grid;
          gap: 10px;
          .button-call {
            display: flex;
            gap: 10px;
            span {
              display: block;
              color: #333;
            }
          }

		  .button-fav {
            display: flex;
            gap: 10px;
            span {
              display: block;
              color: #333;
            }
          }
        }
      }
      &-title,
      &-valid {
        margin-bottom: 10px;
      }
      &-ttc {
        margin-top: 15px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        row-gap: 15px;
      }
    }
    @media(min-width: 1600px) {
      &-item {
        .box:not(.photos) {
          padding: 15px 20px;
        }
        &-title,
        &-valid {
          margin-bottom: 15px;
        }
        .cta-buttons {
          .button {
            min-height: 40px;
          }
         }
      }
    }
  }
}
