html, body {
	margin: 0;
	padding: 0;
	min-width: 320px;
}
html {
  scroll-behavior: smooth;
}
* {
	box-sizing: border-box;
	outline: none;
}
body {
	font-family: 'Inter', sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

figure {
	margin: 0;
	padding: 0;
}
img {
	display: block;
}

p {
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.mt-5 {
	margin-top: 30px !important;
}

.responsive-img {
	width: 100%;
	height: auto;
}

main {
  padding: 0 0 50px 0;
}

.container {
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 12px;
}
@media (min-width: 992px) {
	.container {
		padding: 0 25px;
	}
}
@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
		padding: 0;
	}
}
@media (min-width: 1400px) {
	.container {
		max-width: 1300px;
	}
}

.block {
  padding-top: 35px;
  padding-bottom: 35px;
  @media(min-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.sm-block {
  padding-top: 20px;
  padding-bottom: 30px;
  @media(min-width: 992px) {
    padding-top: 35px;
    padding-bottom: 30px;
  }
}

.mob-dbnone {
	display: none;
	@media (min-width: 992px) {
		display: block;
	}
}
.desk-dfnone {
	display: flex;
	margin-top: 5px;
	@media (min-width: 992px) {
		display: none;
	}
}

.mt0 {
	margin-top: 0;
}
.mb0 {
	margin-bottom: 0;
}
.pt0 {
	padding-top: 0;
}
.pb0 {
	padding-bottom: 0;
}

.text-center {
	text-align: center;
}

.bl {
	margin-bottom: 10px;
  .badge-items {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
}

.about-block img,
.buy-banner img,
.docs img,
.detail-car-photos img {
	pointer-events: none;
}

.next-button {
	width: 100%;
}
.pagination {
	text-align: center;
	a {
		color: #4de091;
	}
}

.comparison-button{
	a{
		width: 100%;
		height: 100%;
		text-decoration: none;
		color: white;
	}
}
