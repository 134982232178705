// Credit front banner 1 //

.credit-banner1 {
	background: url("../images/home/top-slider/1/pattern.png") repeat;
	width: 100%;
	height: 300px;
	.container {
		display: grid;
		grid-template-columns: repeat(1,minmax(0,1fr));
		align-items: center;		
	}
	.col {
		text-align: center;
	}
	.text {
		font-size: 20px;
		color: #000;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
		.y16 {
			max-width: 300px;
			width: 40%;
			height: auto;
			margin: 0 auto;
			z-index: 2;
			position: relative;
		}
			.percent {
				background-color: #4DE091;
				border-radius: 20px 0 20px 0;
				color: #fff;
				font-size: 65px;
				font-weight: 700;
				line-height: 1;
				display: inline-block;
		    padding: 0 25px;
		    margin-bottom: 5px;
		    position: relative;		
			}
				.cars {
					position: relative;
					height: 130px;
					margin-top: -35px;
					.car {
						position: absolute;
						max-width: 60%;
						width: 100%;
						height: auto;
					}
					.car2 {
						bottom: 0;
						right: 0;
					}
				}
					.btn {
						text-transform: uppercase;
						color: #000;
						font-size: 16px;
						font-weight: 600;
						border-radius: 40px;
						background-color: #4DE091;
						text-align: center;
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
						display: flex;
						align-items: center;
						justify-content: center;
						max-width: 280px;
						padding: 8px 10px;
						margin: 0 auto;
						transition: all 0.3s ease-in-out;
						&:hover {
							box-shadow: none;
						}
					}

	@media(max-width:320px) {
		height: 280px;
		.y16 {
			width: 55%;
		}
			.cars {
				height: 90px;
			}
	}

	@media(min-width:460px) {
		height: 340px;
		.text {
			font-size: 24px;
		}
		.y16 {
			max-width: 170px;
			width: 40%;
		}
		.cars {
			height: 150px;
			.car {
				max-width: 50%;
			}			
		}
	}

	@media(min-width:768px) {
		height: 400px;	
		.text {
			font-size: 28px;
		}
			.y16 {
				max-width: 200px;
				width: 40%;
			}
				.cars {
					height: 220px;
					.car {
						max-width: 55%;
					}
				}			
					.btn {
						margin-top: -20px;
					}
	}

	@media(min-width:992px) {
		.container {
			grid-template-columns: 40% auto;
			gap: 20px;
		}		
		.text {
			font-size: 42px;
			margin-bottom: 15px;
		}
			.percent {
				font-size: 140px;
				border-radius: 50px 0 50px 0;
				font-size: 150px;	
				margin-bottom: 15px;
			}
				.y16 {
					max-width: 450px;
					width: 60%;
				}
					.cars {
						height: 250px;
						margin-bottom: 10px;
						margin-top: 10px;
						.car {
							max-width: 400px;
						}
					}
						.btn {
							font-size: 22px;
							border-radius: 40px;
							max-width: 400px;
							padding: 10px 30px;
						}
	}

	@media(min-width:1800px) {
		height: 480px;
		.text {
			font-size: 52px;
			margin-bottom: 15px;
		}
			.percent {
				font-size: 150px;	
			}
			.cars {
				position: relative;
				height: 320px;
				margin-bottom: 0;
				.car {
					max-width: 550px;
				}
			}
			.btn {
				font-size: 26px;
				border-radius: 40px;
				max-width: 460px;
				padding: 15px 40px;
			}		
	}

	@media(min-width:2400px) {
		height: 550px;
		.container {
			grid-template-columns: 35% auto;
		}		
			.cars {
				height: 350px;
				.car {
					max-width: 650px;
				}
			}	
				.btn {
					position: relative;
				}
	}	
}
