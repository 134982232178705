.redemption-result{
    position: absolute;
    top: 50px;
    left: calc(100% + 20px);
    background-color: white;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, .15);
    display: none;
    width: 350px;
    padding: 30px 30px 20px;

    .page-title{
        margin-top: 0px;
    }

    .price{
        display: flex;
        flex-direction: column;

        span{
            font-weight: 600;
        }
    }

    &:before {
        position: absolute;
        top: 20px;
        left: -22px;
        content: url(/static/assets/images/left_form_arrow.png);
        width: 22px;
        height: 66px;

        @media(max-width: 996px) {
            left: 45px;
            transform: rotate(90deg);
            bottom: calc(100% - 23px);
            top: auto;
        }
    }

    @media(max-width: 996px) {
        max-width: 100%;
        z-index: 2;
        top: 100%;
        left: 10px;
    }
}

form.estimating{
    max-width: 100%;
    margin-top: 50px;
    width: 420px;
    position: relative;
    margin-bottom: 50px;

    .field-container{
        display: flex;
    }

    button{
        width: calc(100% - 10px);
        margin: 5px;
        height: 42px;
    }

    .field {
        position: relative;
        border: 1px solid #bdbdbd;
        padding: 10px;
        margin: 5px;

        &.c2{
            max-width: 200px;
        }

        .select{
            position: absolute;
            top: 100%;
            background: white;
            z-index: 1;
            width: calc(100% + 2px);
            left: -1px;
            border: 1px solid #bdbdbd;

            ul{
                margin: 0px;
                padding-left: 0px;

                &::-webkit-scrollbar {
                    width: 10px;
                }
        
                &::-webkit-scrollbar-track {
                    background: var(--second-background-color);
                }
        
                &::-webkit-scrollbar-thumb {
                    background: rgb(220, 220, 220);
                    border-radius: 5px;
                    transition: .2s;
        
                    &:hover {
                        background: rgb(170, 170, 170);
                    }
                }
            }

            li{
                &::marker{
                    content: '';
                }
                transition: .2s;
                padding: 10px;
                cursor: pointer;

                &:hover{
                    background: #f3f3f3;
                }
            }

            input{
                width: calc(100% - 10px);
                margin: 5px;
                padding: 5px;
                margin-bottom: 10px;
            }
        }

        .title{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img{
                width: 15px;
                cursor: pointer;
            }
        }

        &.c2{
            width: 200px;
        }

        ul{
            max-height: 200px;
            overflow-y: auto;
        }

        &.disabled{
            opacity: .5;
            pointer-events: none;
        }
    }
}
