.steps-block {
	border-top: solid 1px #e0e0e0;
	border-bottom: solid 1px #e0e0e0;
	padding: 10px 0 25px;
	font-size: 12px;
	@media(max-width:320px) {
		font-size: 11px;
	}
	@media(min-width: 768px) {
		font-size: 16px;
		padding-left: 10px;
		padding-right: 10px;
	}
}
.steps {
	list-style: none;
	color: #4DE091;
	display: grid;
	grid-template-columns: repeat(4,minmax(0,1fr));
  gap: 0;
  max-width: 700px;
  margin: 0;
	.step {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
    left: -50%;
    position: relative;		
		.label {
			bottom: -10px;
			position: relative;
			text-align: center;
		}
		.circle {
			display: flex;
			align-items: center;
		  position: relative;
		  z-index: 1;
		  top: 15px;
		  &:before {
		  	content: '';
		    border-radius: 100%;
		    width: 21px;
		    height: 21px;
		    display: block;
		    border: solid 2px #e0e0e0;
		    position: relative;
		    background: #fff center url(../images/check.svg) no-repeat;
		  }	    
		}
		&:not(:last-child) {
		  &:after {
		  	content: '';
		  	width: 110%;
		  	height: 8px;
		  	background-color: #e0e0e0;
		  	left: 50%;
		  	position: relative;
		  }
		}
		&:last-child {
		  &:after {
		  	content: '';
		  	height: 8px;
		  }	
		}
		&.completed {
			.circle:before,
			&:after {
			  background-color: #4DE091;
			  border-color: #4DE091;
			}
		}
		&.completed + .step {
			.circle:before {
			  border-color: #4DE091;
			}
		}
	}
}

.alphabet {
	background-color: #F9F9F9;
	padding: 5px 14px;
	border-radius: 6px;
	font-size: 24px;
	font-family: Inter,sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #4DE091;
  display: flex;
	row-gap: 8px;
	column-gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  a {
  	color: #4DE091;
  	text-decoration: none;
  }
  @media(min-width: 992px) {
		row-gap: 15px;
		column-gap: 30px;
		padding: 14px 32px;
  }
}

	.item-title {
		font-size: 20px;
		font-family: Inter,sans-serif;
	  font-style: normal;
	  font-weight: 700;
	  color: #bdbdbd;
	  margin-bottom: 15px;
	}

.alphabet-items {
	margin-bottom: 40px;
	.badge-items {
		display: flex;
		gap: 30px;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
	}	
}

.fade {
  display: none;
  position: fixed;
  height: 200vh;
  width: 100%;
  z-index: 102;
  background-color: rgba(0, 0, 0, 0.5);
}
.form-wrap {
  position: fixed;
  overflow-y: scroll;
  z-index: 103;
  display: none;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 960px;
}
.form-wrap-right {
  right: 0;
}
.form-wrap-center {
  right: 0;
  left: 0;
  .form-container {
  	margin: 0 auto;
  }
}
.form-container {
  background: #fff;
  padding: 20px;
  min-height: 100vh;
}

.body-overlay {
	overflow: hidden;
}

.credit-wrapper {
	.card-title {
		font-size: 22px;
	}
	.card-item .box:not(.photos).box-ttc .card-price {
		display: block;
		margin-bottom: 20px;
	}
}