.marks-list2 {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  margin: 0 0 -15px;
  list-style: none;
  a {
    text-decoration: none;
    color: #222;
    &:hover {
      color: #4de091;
    }
    span {
      color: #828282;
    }
  }
  @media(min-width:992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}