.badge-block {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
  @media(min-width: 375px) {
    gap: 15px;
  }
  .badge {
    position: relative;
    display: inline-block;
    text-decoration: none;
    border-radius: 6px;
    padding: 4px 20px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    &.lg {
      padding: 10px 20px;
    }
    &:hover {
      transition: 0.3s;
      background-color: #F3F3F3;
    }
    &-label { 
      position: absolute;
      top: -5px;
      right: -25px;
      color: #fff;
      background-color: #C584E3;
      min-width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-weight: 400;
      font-size: 14px;
    }
    &-bordered {
      border: solid 1px #e0e0e0;
      &-label {
        top: -14px;
        right: -12px;
      }
    }
    &.purple {
      background-color: #c584e3;
      color: #fff;
    }
  }
}