.buy-banner {
	.left {
		padding-right: 20px;
	}
	.primdark {
		color: #40B978;
	}
	.banner-title {
		font-weight: 900;
		font-size: 24px;
		margin: 30px 0 20px 0;
		padding: 0;
		&:after {
			content: '';
			background-image: url(../images/money.svg);
			background-size: contain;
			background-repeat: no-repeat;
			height: 54px;
			width: 54px;
			display: block;
			float: right;
		}	
	}
	.slogan {
		font-weight: 500;
		font-size: 20px;
		color: #222;
		padding-left: 10px;
	}
	.button {
		width: 100%;
	}
	.form {
		margin-top: 15px;
		.fieldset {
			margin-bottom: 10px;
		}
		.col2 {
			display: flex;
			gap: 10px;
			flex-direction: column;
		}
	}
	@media(min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	@media(min-width: 992px) {
		grid-template-columns: 40% 60%;
		.left {
			padding-right: 70px;
		}
		.banner-title {
			font-size: 48px;
			width: 90%;
			margin-top: 0;
			&:after {
				height: 90px;
				width: 90px;
			}
		}
		.slogan {
			font-size: 24px;
			padding-left: 0;
		}
		.form {
			width: 50%;
			margin-top: 38px;
			.col2 {
				flex-direction: row;
			}
		}
	}
}
