.overlay {
  display: none; 
  position: fixed; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 105;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);  
  &.active {
    display: block;
    overflow-y: auto;
  }
  &.menu-active {
    z-index: 100;
  }
}
body {
  &.overlay-active {
    overflow: hidden;
  }
}

.ss-content {
  z-index: 101 !important;
}
.overlay-active .ss-content {
  z-index: 101 !important;
}

.modal {
  width: 560px;
  position: fixed;
  z-index: 106;
  top: -150%;
  left: 50%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 6px;
  transform: translate(-50%,-50%);
  transition: .4s ease;
  &.active {
    top: 50%;
  }
  @media(max-width: 992px) {
    transform: translateX(-50%);
    &.active {
      top: 10px; 
    }
  }
  .close {
    float: right;
    position: relative;
    z-index: 2;
    max-width: 16px;
    max-height: 16px;
    cursor: pointer; 
  }
  @media (max-width: 576px) {
    min-width: 310px;
    width: 95%;
  }
}


.modal {
  .description {
    margin-top: 25px;
    margin-bottom: 16px;
    .title2 {
      font-weight: 700;
      font-size: 20px;
    }
    p:not(:first-child) {
      margin-top: 0;
    }
    p:not(:last-child) {
      margin-bottom: 0;
    }
  }
  .mini-card {
    color: $black2;
    display: grid;
    grid-template-columns: 38% 62%;
    gap: 16px; 
    padding-bottom: 11px;
    .price {
      padding-top: 8px;
      color: $primdark;
      font-size: 14px;
      span {
        font-weight: 700;
        font-size: 14px;
        color: $black2;
        @media (min-width: 992px) {
          font-size: 16px;
        }
      }
      &.credit-price {
        display: grid;
        grid-template-columns: repeat(2,minmax(0,1fr));
        align-items: center;
        width: 70%;
        gap: 10px;
        @media(max-width: 576px) {
          width: 100%;
        }
        .month-pay {
          font-size: 8px;
          color: $black2;
          display: flex;
          flex-direction: column;
          .pay {
            font-weight: 700;
            font-size: 14px;
            color: $primdark;
          }
        }
      }
    }
    .props {
      border-bottom: solid 1px $graybg;
      padding-bottom: 8px;
      .prop {
        font-size: 14px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;
        width: 70%;
        padding-bottom: 5px;
        @media (max-width: 576px) {
          width: 100%;
        }
        .name {
          color: $gray2;
        }
      }
    }
    .car-name {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 8px;
      margin-top: 0;
      width: 90%;
    }
  }
    
  .ok-block {
    .button {
      margin: 0 auto;
    }
  }
  .actions-block {
    margin-top: 16px;
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center; 
    &.center {
      justify-content: center;
      grid-template-columns: auto;
    }
    .button {
      min-height: 42px;
    }
    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;
      align-items: initial;
    }
  }

  &.modal-message {
    .actions-block {
      grid-template-columns: auto;
      .button {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .box {
    .label {
      display: block;
      font-weight: 700;
      font-size: 12px;
      padding-bottom: 8px;
      &.mob-label {
        display: none;
      }
      &.gray-label {
        color: $gray3;
      }
      &.title-label {
        font-size: 14px;
        color: $black2;
      }
      @media (max-width: 576px) {
        display: block;
        font-weight: 700;
        font-size: 12px;
        padding-bottom: 8px;
        &.mob-label {
          display: block;
        }
      }
    }
    .fieldset {
      gap: 16px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      @media (max-width: 576px) {
        display: initial;
      }
      &:first-child {
        margin-top: 20px;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      &.mb {
        margin-bottom: 32px;
      }
      @media (max-width: 576px) {
        .col {
          margin-bottom: 12px;
        }
        &:last-child {
          .col:last-child {
            .textfield {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .ss-main {
      border-color: $gray5;
      color: $gray2;
      .ss-single {
        color: $gray2;
      }
    }
  }
}//end of modal




.modal2 {
  .close {
    float: right;
    cursor: pointer;
    position: relative;
    z-index: 2;
  }
  .title {
    padding: 0;
    @media(max-width:768px) {
      font-size: 16px;
    }
  }
  .form-wrapper {
    padding: 20px;
  }
  .form-container {
    padding: 0;
  }
}






.modal-car-info {
  .title {
    display: flex;
    align-items: center;  
    img {
      margin-right: 10px;
    }
  }
}