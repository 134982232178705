.placeholder-car {
	cursor: pointer;
	background-color: #F3F3F3;
	border-radius: 6px;
	display: none;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 50px 10px;
	color: #bdbdbd;
	font-family: 'Inter', sans-serif;
	font-weight: 900;
	font-size: 16px;
	@media(min-width: 768px) {
		display: flex;
	}
	@media(min-width: 1280px) {
		padding: 25px 10px 10px;
		max-width: 90%;
	}
}