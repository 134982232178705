.credit-form {
	.button {
		height: 55px;
		font-size: 16px;
	}
	.field {
    background: #fff;
    position: relative;
    margin-bottom: 16px;
    transition: .2s;
    &:hover{
      opacity: .7;
      border-color: #c584e3!important;
    }

    &.field2 {
      margin-bottom: 20px;
    }
    &::before {
      background-repeat: no-repeat;
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      display: inline-block;
      top: 10px;
      bottom: 0;
      right: 12px;
      cursor: pointer;
    }
		&-car {
      &:before {
  			@include car($color: 'white');
  		}
      input {
        background: #c584e3;
        color: white;

        &::-webkit-input-placeholder{
          color: white;
        }

        &:hover{
          border-color: #c584e3!important;
        }
      }
    }
	}
  .textfield {
    padding-right: 50px;
  }
  .pay-calc {
    display: flex;
    gap: 30px;
    align-items: center;
    font-size: 14px;
    .data:not(:last-child) {
      margin-bottom: 12px;
    }
    .num {
      color: #4DE091;
      font-weight: 900;
      font-size: 24px;
      &.per-month {
        color: #40B978;
        font-size: 16px;
      }
    }
    @media(max-width: 576px) {
      gap: 20px;
    }
    @media(max-width: 576px) {
      .num {
        display: block;
      }
    }
  }
  .credit-block2 {
    display: none;
  }
	@media(min-width: 992px) {
		border-radius: 5px;
		border: solid 4px #e0e0e0;
		padding: 20px;
		margin-bottom: 40px;
	}
}

.select-card-mob {
  display: none;
}
@media(min-width: 768px) {
  .select-card-mob {
    display: block;
  }
  .card-item-select-mob {
    display: none;
  }
}



.card-item-select-mob {
  border: solid 1px #f3f3f3;
  border-radius: 6px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  margin-bottom: 35px;
  img {
    border-radius: 6px 0 0 6px;
  }
  .box {
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .label {
    color: #828282;
  }
  .item {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  @media(max-width: 376px) {
    .box {
      gap: 2px;
    }
  }
  @media(min-width: 576px) {
    gap: 20px;
    .box {
      gap: 8px;
    }
  }
  @media(min-width: 992px) {
    margin-top: 10px;
    font-size: 16px;
    gap: 20px;
  }
}

.card-item.select-car {
  .card-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .price {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .card-ttc {
    row-gap: 20px;
    column-gap: 10px;
    li::before {
      background-size: 24px;
      height: 24px;
      width: 24px;
    }
  }
}
