$black1: #000;
$black2: #222;
$black3: #333;

$white: #fff;

$gray2: #4f4f4f;
$gray3: #828282;
$gray4: #bdbdbd;
$gray5: #e0e0e0;

$graybg: #F3F3F3;

$primmain: #4DE091;
$primdark: #40B978;
$primhover: #33FF92;

$secondmain: #C584E3;
$sechover: #D987FF;
$secactive: #A770C1;

$t9: #F9F9F9;