.catalog {
  display: grid;
  gap: 25px;

  #clear-all{
    color: rgb(150, 150, 150);
    cursor: pointer;

    &:hover{
      opacity: .6;
    }
  }

  .seo-text-container {
    height: 70px;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(180deg, white, rgba(0, 0, 0, 0));
    scroll-margin: 100px;
  }

  .filter-block {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    box-shadow: 0 4px 16px 0 rgba(77,224,145,.3);
    border: 1px solid rgba(77,224,145,.25);
    border-radius: 4px;
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    .filter-hide-mob {
      display: none;
      gap: 15px;
      @media(min-width: 992px) {
        display: grid;
        grid-column: 1/span 2;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
      }
    }
    @media(min-width: 992px) {
      .show-filters {
        display: none;
      }
    }
    .field {
      select { width: 100%; }
      label {
        display: block;
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 12px;
        color: #333;
      }
      &:not(.c2) {
        grid-column: 1/span 2;
      }
      &.c2 {
        input { width: 50%; }
      }
    }
    .range {
      display: flex;
      gap: 10px;
      input:not(.price) {
        width: 50%;
      }
    }
  }
  @media(min-width: 992px) {
    grid-template-columns: 300px auto;
    .filter-block {
      .field {
        label {
          font-size: 14px;
        }
      }
    }
  }
}

.lot-butt-container{
  display: flex;
  gap: 15px;
  margin-bottom: 30px;

  .card-item-wrapper{
    width: 300px;
  }

  input{
    padding: 5px 10px;
    border: 1px solid rgb(220, 220, 220);
    width: 280px;
    max-width: 50%;
  }
}

.lot-container{
  display: flex;

  p{
    color: rgb(150, 150, 150);
    margin: 0px;
    margin-right: 10px;
  }
}
