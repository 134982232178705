.docs {
	.items {
		.item:last-child {
			display: none;
		}
	}
	@media (min-width: 768px) {
		.items {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			.item:last-child {
				display: grid;
			}
		}
	}
	@media (min-width: 992px) {
		.items {
			grid-template-columns: 55% 45%;
		}
	}
}