.bordered-title-wrap {
	text-align: center;
	.bordered-title {
		display: inline-block;
		font-size: 22px;
	}
	@media(min-width: 1200px) {
		text-align: left;
	}
}

.bordered-title {
  margin-top: 0;
  margin-bottom: 18px;
  width: auto;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	color: #333;
	&:after {
		content: '';
		width: 100px;
		display: block;
		border-bottom: 6px solid #4DE091;
		margin-left: 20px;
		margin-top: 6px;
	}
	@media(min-width: 992px) {
		font-weight: 700;
		font-size: 20px;
		margin-bottom: 30px;
		&.lg {
			font-weight: 800;
			font-size: 24px;
		}
	}
	@media(min-width: 1200px) {
		&.lg {
			font-size: 30px;
		}
	}
}

.short-title {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -moz-box;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	line-clamp: 1;
	box-orient: vertical;
	transition: .4s ease;
}

.page-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #333;
  &.h2 {
    font-size: 18px;
  }
}
.page-title.up {
  text-transform: capitalize;
}
@media(min-width: 768px) {
  .page-title {
    margin: 15px 0;
  }
}
@media(min-width: 992px) {
  .page-title {
    font-size: 24px;
    margin: 15px 0 10px;
    &.h2 {
    	font-size: 20px;
    }
  }
}
@media(min-width: 1800px) {
  .page-title {
    font-size: 26px;
    margin: 0 0 30px;
    &.h2 {
    	font-size: 24px;
    }
  }
}
