.slider-scale {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	width: 100%;
	height: 20px;
	position: relative;
	margin-top: 15px;
}
.slider-scale-tick {
	position: relative;
}
// .slider-scale-tick:before {
// 	content: "";
// 	border-left: 1px solid #677680;
// 	display: block;
// 	position: absolute;
// 	height: 5px;
// }
.slider-scale-big:before {
	height: 10px;
}
.slider-scale-tick:after {
	top: -40px;
	counter-increment: section;
	content: attr(data-label);
	transform: translateX(-50%);
	text-align: center;
	position: absolute;
	font-family: 'Lato', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	color: $gray3;
}


.ui-widget-header {
	background: $primmain;
	border-radius: 5px;
}
.ui-widget.ui-widget-content {
	border: none;
}
.ui-widget-content {
	background: $gray5;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-br, .ui-corner-right {
  border-bottom-right-radius: 5px;
}
.ui-corner-all, .ui-corner-bl, .ui-corner-bottom, .ui-corner-left {
  border-bottom-left-radius: 5px;
}
.ui-corner-all, .ui-corner-right, .ui-corner-top, .ui-corner-tr {
  border-top-right-radius: 5px;
}
.ui-corner-all, .ui-corner-left, .ui-corner-tl, .ui-corner-top {
  border-top-left-radius: 5px;
}
.ui-widget {
	font-size: 6px;
}

.ui-slider-horizontal .ui-slider-handle {
	top: -8px;
	margin-left: -9px;
}
.ui-slider .ui-slider-handle {
  width: 19px;
  height: 19px;
  border-radius: 100px;
}
.ui-state-default, .ui-widget-content .ui-state-default {
	border: none;
	background: $primdark;
}

.ui-slider-handle {
  transition-property: left;
  transition-duration: 0.10s;
}