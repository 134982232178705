.comparison-container{
    overflow-x: scroll;

    .wrapper{
        max-width: 200px;
        min-width: 200px;
        padding: 10px;

        p{
            overflow-wrap: break-word;
        }
    }
}

.triangle{
    height: 13px;
    cursor: pointer;
    width: 20px;

    &.reverse{
        transform: rotate(180deg);
    }
}

.fields{
    margin-top: 40px;
    margin-bottom: 40px;

    hr{
        position: absolute;
        width: 1000%;
        margin-top: 5px;
        height: 0;
        border: 0;
        border-top: 2px solid rgb(240, 240, 240);

        &:first-child{
            margin-top: -10px;
        }
    }
}

.scroll-btn{
    position: absolute;
    top: 200px;
    z-index: 2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: none;
    transition: .3s;
    font-size: 22px;
    background-color: #4de091;
    color: white;
    cursor: pointer;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    &.left{
        left: -20px;

        @media(max-width: 996px) {
            left: 20px;
        }
    }

    &.right{
        right: -20px;

        @media(max-width: 996px) {
            right: 20px;
        }
    }

    &:hover{
        transform: scale(1.2);
    }
}

/* хром, сафари */
.comparison-container::-webkit-scrollbar { width: 0; }

/* ie 10+ */
.comparison-container { -ms-overflow-style: none; }

/* фф (свойство больше не работает, других способов тоже нет)*/
.comparison-container { overflow: -moz-scrollbars-none; }

.comparison-title{
    padding-top: 30px;

    @media(max-width: 996px) {
        display: flex;
        flex-direction: column;
    }

    a{
        margin: 0px 10px;
        cursor: pointer;

        @media(max-width: 996px) {
            margin-left: 0px;
        }
    }
}
