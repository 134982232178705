.catalog-block {
  display: grid;
  grid-template-columns: repeat(1,minmax(0,1fr));
  gap: 20px;
  &::after {
    display: none;
  }
}
/*.view-tile {
  .card-ttc {
    li:before {
      display: none;
    }   
  }
}*/
@media(min-width: 768px) {
  .catalog-block {
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
}  
@media(min-width: 992px) {
  .catalog-page {
    grid-template-columns: 32% 1fr;
  } 
  .catalog-block {
    &.view-tile {
      gap: 20px;
      .box {
        padding-bottom: 0;
      }
      .price-box {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  } 
} 
@media(min-width: 1280px) {
  .catalog-page {
    grid-template-columns: 26% 1fr;
  }
  .catalog-block {  
    &.view-tile { 
      grid-template-columns: repeat(3,minmax(0,1fr));
      &.full-width {
        grid-template-columns: repeat(4,minmax(0,1fr));
      }
    }
    &.view-list {
      grid-template-columns: repeat(1,minmax(0,1fr));
      .card-item {
        display: grid;
        grid-template-columns: repeat(3,minmax(0,1fr));
      }  
    }
  }
}
@media(min-width: 1800px) {
  .catalog-block {
    &.view-tile {
      .cta-buttons {
        .button-call {
          width: 32px;
        }
      } 
    }
  }
}


.toggle-block {
  position: relative;
  .toggle-line {
    position: absolute;
    bottom: 15px;
    z-index: 2;
    width: 100%;
    border-bottom: solid 1px #bdbdbd;
    cursor: default;
    .toggle-wrap {
      position: relative;
      left: 20px;
      bottom: -10px;
      padding: 0 10px;
      background-color: #fff;
      border: none;
      .toggle-button {
        padding: 4px 20px;
        background-color: #fff;
        border-radius: 6px;
        border: solid 1px #bdbdbd;
        font-family: Inter,sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #bdbdbd;
        cursor: pointer;
        &::after {
          content: '';
          position: absolute;
          bottom: 2px;
          left: 16px;
          height: 12px;
          width: 12px;
          background: center no-repeat url(../images/arrow-down-gray.svg);
          background-size: 12px;
          transform: rotate(-180deg);
        }
      }
    }
  }
  &.mh-init {
    padding-bottom: 50px;
    &.models {
      margin-bottom: 30px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 50px;
      width: 100%;
      background: #fff;
      background: linear-gradient(transparent, #fff 30%);
    } 
  }
  &.mh {
    max-height: 100px;  
    overflow: hidden;
    .toggle-line .toggle-wrap .toggle-button::after {
      transform: rotate(0deg);
      bottom: 2px;
    }
  }
  &.mh250 {
    max-height: 220px;
  }
}